.am-progress-outer {
  background-color: #ddd;
  display: block;
}
.am-progress-fixed-outer {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
}
.am-progress-hide-outer {
  background-color: transparent;
}
.am-progress-bar {
  border: 2px solid #108ee9;
  -webkit-transition: all .3s linear 0s;
  transition: all .3s linear 0s;
}
